'use client';

import { styled } from 'styled-components';
import { px2Rem } from '../../utils/px2Rem';
import { palette } from '../../colors';
import { ASSETS_SVGS_BASE_URL } from '../../constants';
import { themeVarToCssVar } from 'uibook-themes';

const StyledFormCheckbox = styled.label<{
  $errorBorder?: boolean;
  $backgroundColor?: string;
  $size?: number;
  $padding?: string;
  $hoverColor?: string;
}>`
  ${({ $errorBorder, $backgroundColor, $size, $padding, $hoverColor }) => `
    min-height: ${px2Rem(24)};
    display: flex;
    position: relative;
    padding: ${$padding ?? px2Rem(12)};
    background-color: ${$backgroundColor ?? palette.charcoal[100]};
    color: ${palette.charcoal[500]};
    font-size: ${px2Rem(16)};
    line-height: ${px2Rem(20)};
    border-radius: ${px2Rem(4)};
    cursor: pointer;
    border: ${$errorBorder ? `2px solid ${palette.error[500]}` : 'none'};

    &:hover {
      background-color: ${$hoverColor ?? palette.charcoal[200]};
    }

    .checkbox {
      position: relative;
      top: ${px2Rem(-1)};

      input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
        margin: 0;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        height: ${px2Rem($size ?? 24)};
        width: ${px2Rem($size ?? 24)};
        background-color: ${palette.white};
        border: 1px solid ${palette.charcoal[300]};

        &::after {
          content: "";
          position: absolute;
          display: none;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: ${px2Rem(14)};
          height: ${px2Rem(10)};
          background-image: url("${ASSETS_SVGS_BASE_URL}/tick-white.svg");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }

      input:checked ~ span {
        border: none;

        &::after {
          display: block;
        }
      }

      input:focus ~ span {
        border-color: ${themeVarToCssVar('primary')};
      }

      input:disabled ~ span {
        border: none;
      }
    }

    .label {
      padding-left: ${px2Rem(36)};
      display: flex;
      align-items: center;
    }
  `}
`;

export { StyledFormCheckbox };
