import { getThemeNameByMerchantId, Theme } from 'uibook-themes';
import { ProductsQuery } from '../types/navData.types';

type UpdateCategoryNameProps = {
  model?: string | null | undefined;
  category?: string | null | undefined;
};

export const updateCategoryName = ({ model, category }: UpdateCategoryNameProps) => {
  const themeName = getThemeNameByMerchantId(process.env.NEXT_PUBLIC_RAYLO_MERCHANT_ID);

  if (themeName === Theme.FLEX) {
    if (!model?.includes('PlayStation 5')) {
      return 'Accessories';
    }

    if (model.includes('PlayStation 5') && model.includes('with')) {
      return 'Bundles';
    }
  }
  return category;
};

export const updateCategoryNameInApiResponse = (products: ProductsQuery[]): ProductsQuery[] => {
  return products.map((product) => {
    if (!product) {
      return product;
    }

    return {
      ...product,
      category: {
        ...product?.category,
        displayName: updateCategoryName({
          model: product?.displayName,
          category: product?.category?.displayName,
        }),
      },
    };
  });
};
