import { ReactNode } from 'react';
import { IndexUiState } from 'instantsearch.js';
import { UseRefinementListProps } from 'react-instantsearch';
import { IconPhone } from 'uibook-icons/solid/IconPhone';
import { IconLaptop } from 'uibook-icons/solid/IconLaptop';
import { IconTv } from 'uibook-icons/solid/IconTv';
import { IconConsole } from 'uibook-icons/solid/IconConsole';
import { IconSmartwatch } from 'uibook-icons/solid/IconSmartwatch';
import { IconTablet } from 'uibook-icons/solid/IconTablet';
import { IconSpeaker } from 'uibook-icons/solid/IconSpeaker';
import { IconDesktopComputer } from 'uibook-icons/solid/IconDesktopComputer';
import { IconHeadphones2 } from 'uibook-icons/solid/IconHeadphones2';
import { SvgElement } from 'uibook-icons/types/icons.types';
import { IconBundle } from 'uibook-icons/solid/IconBundle';
import { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';

export type SearchRefinementFacet = {
  /** Title to show at the top of the facets */
  title: string;
  /** The attribute to apply the changes to */
  attribute: string;
  /** The layout in the UI of the facets */
  type: 'list' | 'labels';

  uiConfig?: {
    /** If the items should render an icon to the left of the item name */
    renderWithIcon?: boolean;
    /** An optional overrider of the item labels */
    labelMap?: Record<string, string>;
    /** If the facet should be visible, based on the current state of the index */
    isVisible?: (indexUiState: IndexUiState) => boolean;
    /**
     * If the facet should show the fade element when the list is long, and how many items should be
     * in the list to trigger the fade element to show.
     */
    showFadeWhenMoreThan?: number;
    /** If the facet should show the pre-approval banner and only the pre-approval filter checkbox */
    showOnlyPreApprovalInfo?: boolean;
    /** The pre-approved amount for the customer */
    preApprovedAmount?: number;
    /** Override the `searchConfig.sortBy` method */
    sortBy?: (items: RefinementListItem[]) => RefinementListItem[];
  };

  searchConfig?: Partial<UseRefinementListProps>;
};

export type FilterTypeProps = {
  facet: SearchRefinementFacet;
  preApprovalBanner?: ReactNode;
};

export const filterCategoryIcons: Record<string, SvgElement> = {
  Phones: IconPhone,
  Consoles: IconConsole,
  TVs: IconTv,
  Watches: IconSmartwatch,
  Tablets: IconTablet,
  Laptops: IconLaptop,
  Speakers: IconSpeaker,
  Desktops: IconDesktopComputer,
  Headphones: IconHeadphones2,
  /** Only used for `Theme.FLEX` */
  Bundles: IconBundle,
  Accessories: IconHeadphones2,
};
