import { createProductSubtitle } from './createProductSubtitle';
import { getVariantSlugForUrl } from './getDefaultVariantSlug';
import { CostSummaryPriceRecord, ProductForPLP, SearchVariantForPLP } from '../types/productTypes';
import { unique } from './arrays.utils';
import { typesenseProductsFields } from 'uibook/utils/search';
import pluralize from 'pluralize';
import { ColourHex } from '@/types/colourHex';
import { formatMarkdownToArrayOrUndefined } from './strings.utils';
import { updateCategoryName } from 'uibook/utils/productCategoryHelpers';

/**
 * Converts a given cost to a price group by rounding down to the nearest multiple of 10.
 *
 * @param {number} cost - The cost to be converted.
 * @returns {number} - The price group, which is the largest multiple of 10 less than or equal to
 *   the given cost.
 */
const convertToPriceGroup = (cost: number) => {
  return Math.floor(cost / 10) * 10;
};

type SearchPricingFields = Pick<
  SearchVariantForPLP,
  | 'lowestMonthlyCost'
  | 'monthlyprice'
  | 'monthlypriceBeforeTax'
  | 'priceGroup'
  | 'priceGroupBeforeTax'
>;
export const getPricingFieldsFromLowestMonthlyCost = (
  lowestMonthlyCost: CostSummaryPriceRecord,
): SearchPricingFields => {
  return {
    lowestMonthlyCost,
    ...(lowestMonthlyCost.valueAfterTax && {
      [typesenseProductsFields.monthlyprice]: lowestMonthlyCost.valueAfterTax,
    }),
    ...(lowestMonthlyCost.valueBeforeTax && {
      [typesenseProductsFields.monthlypriceBeforeTax]: lowestMonthlyCost.valueBeforeTax,
    }),

    ...(lowestMonthlyCost.valueAfterTax && {
      [typesenseProductsFields.priceGroup]: convertToPriceGroup(lowestMonthlyCost.valueAfterTax),
    }),
    ...(lowestMonthlyCost.valueBeforeTax && {
      [typesenseProductsFields.priceGroupBeforeTax]: convertToPriceGroup(
        lowestMonthlyCost.valueBeforeTax,
      ),
    }),
  };
};

/** This function is used to format the product data to be used in the Typesense products collection */
export const getProductsCardDataModel = (productData: ProductForPLP[]): SearchVariantForPLP[] => {
  const productCardData = productData.flatMap(
    ({
      displayName,
      id,
      position,
      manufacturer,
      category,
      variants,
      available,
      keyFeatures,
      lowestCostSummary,
    }) => {
      const formattedKeyFeatures = formatMarkdownToArrayOrUndefined(keyFeatures);

      const availableVariants = variants
        .filter(({ available }) => available)
        .sort((a, b) => a.position - b.position);

      const uniqueHexColours = unique(
        availableVariants
          .map(
            ({ optionValues }) =>
              optionValues?.find((opt) => opt?.optionType?.slug === 'colour')?.raw || null,
          )
          .filter((colour): colour is string => !!colour),
      ) as ColourHex[];

      const isAvailable = available && variants.some((variant) => variant.available);

      const productVariantConditions =
        unique(availableVariants.map(({ condition }) => condition)) ?? [];

      const defaultVariant = availableVariants[0];

      if (!defaultVariant) {
        return null;
      }

      const allOptionTypes = variants.flatMap(({ optionValues }) => optionValues ?? []);

      const images = defaultVariant.images ?? null;
      const catDisplayName = updateCategoryName({
        model: displayName,
        category: category?.displayName,
      });
      const variantSlug = getVariantSlugForUrl(defaultVariant);

      const lowestMonthlyCost = {
        valueAfterTax: lowestCostSummary?.recurring.totalAmount.valueAfterTax.value ?? null,
        valueBeforeTax: lowestCostSummary?.recurring.totalAmount.valueBeforeTax.value ?? null,
      };

      const pricingFields = getPricingFieldsFromLowestMonthlyCost(lowestMonthlyCost);

      return {
        id,

        /** Fields only used for the `ProductCard` */
        variantId: defaultVariant.id,
        variantImageUrl: images?.[0]?.url,
        variantSlug,
        colours: uniqueHexColours,
        available: isAvailable,
        eligible: true,
        cardDisplaySpecification: createProductSubtitle(
          variantSlug,
          catDisplayName ?? '',
          defaultVariant,
          allOptionTypes,
        ),

        /** Fields used across Typesense, the UI and the `ProductCard` */
        [typesenseProductsFields.position]: position,
        [typesenseProductsFields.model]: displayName,
        [typesenseProductsFields.make]: manufacturer.displayName,
        [typesenseProductsFields.condition]: productVariantConditions,

        ...(catDisplayName && {
          [typesenseProductsFields.category]: pluralize(catDisplayName.toLowerCase()),
        }),

        ...(formattedKeyFeatures && {
          [typesenseProductsFields.keyFeatures]: formattedKeyFeatures,
        }),

        ...pricingFields,
      };
    },
  );

  return (
    productCardData
      .filter((variant): variant is SearchVariantForPLP => !!variant)
      .sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0)) ?? []
  );
};
